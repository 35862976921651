<template>
  <div id="app">
    <router-view class="bootstrap-wrapper" />
  </div>
</template>

<script>
import Repository, { baseApi, secret } from './repository.js';

export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }
  },
  // created(){
  //   let uri = window.location.search.substring(1);
  //   let params = new URLSearchParams(uri);
  //   let m_id = params.get("m_id");
  //   let m_password = params.get("m_password");
  //   if(m_id&&m_password)this.auto_login_after_signup(m_id,m_password);
  // },
  mounted() {
    setTimeout(() => {
      let m_id = this.$route.query.m_id;
      let m_password = this.$route.query.m_password;
      if (m_id && m_password) this.auto_login_after_signup(m_id, m_password);
    }, 100);
  },
  methods: {
    async auto_login_after_signup(m_id, m_password) {
      console.log(m_id, m_password);
      var url = baseApi + "Login/user_login_after_register/" + secret;
      var data = new FormData();
      data.set("id", m_id);
      data.set("password", m_password);
      await Repository.post(url, data)
        .then(response => {
          console.log(response.data)
          if (response.data.error == 0) {
            this.set_session_userData(response.data.data);
            // .............................................................
            let remember = {};
            remember.username = response.data.data.username;
            localStorage.setItem("_rememberUser_", JSON.stringify(remember));
            // .............................................................
            const fname = response.data.data.fname;
            const lname = response.data.data.lname;
            this.$swal("Login Successful", `${fname} ${lname}`, "success");
            // .............................................................
            window.location.reload();
          } else {
            this.$swal("Not found account!", "username or password is incorrect", "warning", { dangerMode: true });
          }
        })
        .catch(error => ({ error: JSON.stringify(error) }));
    },
  }
}
</script>

<style>
@import './has-tooltip.css';
@import url(//fonts.googleapis.com/css?family=Raleway:300);

#app {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: none !important;
  /* width: 160px;  */
  /* height: 1.2em;  */
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
  border: 2px solid #024fa0;
  border-radius: 10px;
}

.modal.show {
  display: block !important;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}

.swal-modal {
  border: 4px solid #024fa0;
  border-radius: 10px;
}

@media (min-width: 576px) {
  .modal-mdx {
    max-width: 400px
  }
}

.text-mta {
  color: #004393;
}

.text-black {
  color: black;
}
</style>
