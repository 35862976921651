import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("Login")
  }, {
    path: '/logout',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/logout/:username/:password',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/Personal',
    component: lazyLoad("Personal/Base"),
    children: [
      {
        path: '',
        redirect: '/personal/profile',
      },
      {
        path: 'profile',
        name: 'PersonalProfile',
        component: lazyLoad("Personal/Profile")
      },
      {
        path: 'information',
        name: 'PersonalInformation',
        component: lazyLoad("Personal/Information")
      },
      {
        path: 'password',
        name: 'PersonalPassword',
        component: lazyLoad("Personal/Password")
      },
      // {
      //   path: 'referral',
      //   name: 'PersonalReferral',
      //   component: lazyLoad("Personal/Referral")
      // },
      {
        path: 'earning',
        name: 'PersonalEarningDashboard',
        component: lazyLoad("Personal/EarningDashboard"),
      },
      {
        path: 'support_center',
        name: 'PersonalSupportCenter',
        component: lazyLoad("Personal/SupportCenter")
      },
      {
        path: 'discover_more',
        name: 'PersonalDiscoverMore',
        component: lazyLoad("Personal/DiscoverMore")
      },
      {
        path: 'send_link',
        name: 'PersonalSendLink',
        component: lazyLoad("Personal/SendLink")
      },
      {
        path: 'send_link/email',
        name: 'PersonalSendLinkEmail',
        component: lazyLoad("Personal/SendLinkEmail")
      },
      {
        path: 'send_link/text',
        name: 'PersonalSendLinkText',
        component: lazyLoad("Personal/SendLinkText")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
