<script>
import moment from 'moment'
var md5 = require('md5');

import { personal } from './personalData.js';

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      screen_mode: "",
    }
  },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    set_session_personalData(data) {
      let acc = {
        id: data.id,
        code: data.code,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        img: data.img,
        cover: data.cover,
        token: data.token
      }
      localStorage.setItem("_personalData_", JSON.stringify(acc));
    },
    check_login(account) {
      console.log("check_login", account, personal);
      if (account.password == personal.password) {
        if (account.token == personal.token) {
          this.screen_mode = 'active';
        } else {
          this.screen_mode = 'invalid_token';
        }
        setTimeout(() => { this.loading_screen = false; }, 1000);
      } else {
        // this.redirect("/");
        this.logout();
      }
    },
    check_payment(payment) {
      let now = Date.parse(new Date()) / 1000
      if (payment.expiration < now) this.screen_mode = 'expiration';
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    login_with_token() {
      let tokenData = this.LocalStorage("_tokenData_");
      // let memberData = this.LocalStorage("_memberData_");
      // let accountData = this.LocalStorage("_accountData_");
      // console.log( tokenData );
      // console.log( memberData, accountData );
      if (tokenData.email && tokenData.token && tokenData.type) {
        this.login_token(tokenData);
      }
    },
    async login_token(tokenData) {
      // console.log(tokenData);

      var url = this.api + 'login/token/' + this.secret;
      var data = new FormData();
      data.set('email', tokenData.email);
      data.set('token', tokenData.token);
      data.set('type', tokenData.type);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            let acc = response.data.data;
            this.save_token(acc);
            switch (tokenData.type) {
              case "personal_account":
                // localStorage.setItem("_accountData_",JSON.stringify(acc));
                // this.accountData = acc;
                // this.redirect("/personal");
                this.redirect("/personal/update_profile");
                break;
              case "members":
                // localStorage.setItem("_memberData_",JSON.stringify(acc));
                // this.memberData = acc;
                this.redirect("/members");
                break;
            }
            // this.$swal("Successful Login ","","success");
            this.$emit("close");
          } else {
            this.needPassword = true;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    save_token(data) {
      let tokenData = new Object();
      tokenData.email = data.m_username;
      tokenData.m_img = data.m_img;
      tokenData.token = data.token;
      tokenData.type = data.type;
      localStorage.setItem("_tokenData_", JSON.stringify(tokenData));
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    update_account_data(name, array) {
      let account = this.LocalStorage(name);
      Object.keys(array).forEach((e) => {
        account[e] = array[e];
      });
      localStorage.setItem(name, JSON.stringify(account));
    },
    profile_image(path) {
      if (path == "") path = "../assets/user.jpg";

      return path;
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    logout_personal() {
      let acct = this.sessionData;
      if (this.isFreeAccount(acct)) {
        if (acct.paypal_status !== "Approved Profiles" && !parseInt(acct.approval)) {
          this.ask_for_aprroval();
        } else {
          this.ask_for_logout();
        }
      } else {
        this.ask_for_logout();
      }
    },
    ask_for_aprroval() {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to submit your profile \n for approval before logging out?",
        icon: "info",
        closeOnClickOutside: false,
        buttons: {
          Yes: true,
          cancel: "Later",
        },
      })
        .then((value) => {
          console.log(value);
          if (value) {
            this.redirect("/personal/update_profile");
            setTimeout(() => {
              document.getElementById("approval_btn").click()
            }, 500);
          } else {
            this.redirect("/");
          }
        });
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay logged in', `Logout`], dangerMode: true })
        .then((ok) => {
          if (ok) {
            this.logout();
          }
        });
    },
    logout() {
      localStorage.setItem("_personalData_", JSON.stringify(new Object));
      setTimeout(() => window.location.href = '/', 1);
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    isFreeAccount(acct = null) {
      if (!acct) {
        return false;
      } else if (acct.payment_provider == "free_account" && acct.m_img == "") {
        if (parseInt(acct.m_sex) == 1) {
          acct.m_img = "../app/personal_account/profile/maleart.png";
        } else if (parseInt(acct.m_sex) == 2) {
          acct.m_img = "../app/personal_account/profile/femaleart.png";
        }
        let tokenData = this.LocalStorage("_tokenData_");
        acct.type = tokenData.type;
        // tokenData.m_img = acct.m_img;
        this.save_token(acct);

        return true;
      }
      return (acct.payment_provider == "free_account");
    },
    isAccountActive(acct = null) {
      console.log(acct);
      return true;
      // if(!acct)return false;
      //
      // let active = false;
      // let m_class = parseInt(acct.m_class);
      // let m_pay_status = parseInt(acct.m_pay_status);
      // let free_account = this.isFreeAccount(acct);
      //
      // if(free_account){
      //   active=true;
      // }else if(m_class==3){
      //   active=true;
      // }else if(m_pay_status==2 || m_pay_status==5){
      //   active=true;
      // }else{
      //   const minutesToAdjust = 60*24;
      //   const millisecondsPerMinute = 60000;
      //
      //   let now =  new Date();
      //   let today = new Date( now + (minutesToAdjust*millisecondsPerMinute) ).getTime();
      //   // let next_bill = moment(acct.paypal_next_bill).valueOf();
      //   // let m_class_exp = moment(acct.m_class_exp).valueOf();
      //
      //   let payment_provider = acct.payment_provider.toLowerCase();
      //   switch (payment_provider) {
      //     case "paypal":
      //     // console.log(acct.paypal_next_bill,today,moment(acct.paypal_next_bill).valueOf(),today>moment(acct.paypal_next_bill).valueOf());
      //     if(today<moment(acct.paypal_next_bill).valueOf())active=true;
      //     break;
      //     case "stripe":
      //     // console.log(acct.m_class_exp,today,moment(acct.m_class_exp).valueOf(),today>moment(acct.m_class_exp).valueOf());
      //     if(today<moment(acct.m_class_exp).valueOf())active=true;
      //     break;
      //   }
      // }
      // return active;
    },
    r_cat_name(demo) {
      let text = demo.cat_id;
      if (this.demo_cat.length) {
        let index = this.demo_cat.findIndex((e) => parseInt(e.cat_id) == parseInt(demo.cat_id));
        if (index > -1) return this.demo_cat[index].cat_name;
      } else {
        return text;
      }
    },
    generateRandomDigit(n = 10) {
      const characters = '0123456789';

      let result = '';
      for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result
    },
    getFirstDateOfNextQuarter(inputDate) {
      // Parse the input date
      const currentDate = new Date(inputDate);

      // Calculate the current quarter
      const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

      // Calculate the first month of the next quarter
      const nextQuarterFirstMonth = currentQuarter * 3;

      // Calculate the first day of the next quarter
      const nextQuarterFirstDate = new Date(currentDate.getFullYear(), nextQuarterFirstMonth, 1);

      // Format the result as "YYYY-MM-DD"
      const formattedResult = `${nextQuarterFirstDate.getFullYear()}-${(nextQuarterFirstDate.getMonth() + 1).toString().padStart(2, '0')}-01`;

      return formattedResult;
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_short: function (date) {
      return moment(date).format('D MMM') + " (PST)";
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_chat_message: function (date) {
      return moment(date).format('h:mm');
    },
    date_payout: function (date) {
      return moment(date).format('Do, MMMM YYYY');
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>
